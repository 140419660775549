<div class="flex">
  <div class="flex items-stretch bg-background-white overflow-hidden" [ngClass]="wrapperClasses">
    <div class="flex items-center justify-center w-10 shrink-0 text-white" [ngClass]="iconContainerClasses">
      <lis-icon *ngIf="icon" [name]="icon"></lis-icon>
    </div>
    <div class="px-4 py-2 flex grow items-center text-text-default" [ngClass]="textContainerClasses">
      <div>
        <span class="inline font-bold mr-1" *ngIf="title">{{ title | translate }}</span>
        <span class="inline" *ngIf="subtitle">
          <div class="inline" role="alert" [attr.aria-label]="subtitle | translate">
            {{ subtitle | translate }}
          </div>
        </span>
      </div>
    </div>
    <div *ngIf="hasCloseButton">
      <button type="button" (click)="onCloseClick()" class="text-icon-actions p-2">
        <lis-icon [name]="'action_close-24'"></lis-icon>
      </button>
    </div>
  </div>
</div>
