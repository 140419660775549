import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IconModule } from '@lis-ui/icon';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { CheckboxModule } from '../checkbox';
import { InputModule } from '../input';
import { MultiSelectComponent } from './multi-select.component';

@NgModule({
  imports: [
    CommonModule,
    OverlayModule,
    InputModule,
    IconModule,
    ReactiveFormsModule,
    TranslateModule,
    CheckboxModule,
    DirectivesModule,
  ],
  declarations: [MultiSelectComponent],
  exports: [MultiSelectComponent],
})
export class MultiSelectModule {}
