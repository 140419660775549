import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@lis-ui/icon';
import { TranslateModule } from '@ngx-translate/core';
import { ToastComponent } from './toast.component';

@NgModule({
  declarations: [ToastComponent],
  imports: [CommonModule, IconModule, TranslateModule],
  exports: [ToastComponent],
})
export class ToastModule {}
