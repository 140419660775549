import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@lis-ui/icon';
import { TranslateModule } from '@ngx-translate/core';
import { RadioComponent } from './radio.component';

@NgModule({
  declarations: [RadioComponent],
  imports: [CommonModule, IconModule, IconModule, TranslateModule],
  exports: [RadioComponent],
})
export class RadioModule {}
