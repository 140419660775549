import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { DcuplQueryGroup, Suggestion } from '@dcupl/common';
import { Dcupl, DcuplList } from '@dcupl/core';
import { LisSelectOption } from '@lis-form';
import { DcuplService } from '@lis-services';
import { sortBy, uniqBy } from 'lodash-es';

import { EventEmitter } from '@angular/core';
import {
  LisAutocompleteFilterConfig,
  LisColumnKey,
  LisTableModel,
  LisTextContent,
} from '@lis-types';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'lis-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteComponent<
    OptionKey extends string,
    Model extends LisTableModel,
  >
  implements OnInit, OnDestroy
{
  @Input({ required: true }) dcuplList!: DcuplList<Model>;
  @Input({ required: true }) dcuplAttribute!: LisColumnKey<Model>;
  @Input() placeholder = '';
  @Input() config?: LisAutocompleteFilterConfig;

  @Output() selected = new EventEmitter<OptionKey | null>();

  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger })
  inputAutoComplete?: MatAutocompleteTrigger;

  // use as nativeElement
  @ViewChild('inputElement') inputElement?: { nativeElement: HTMLInputElement };

  public inputFormControl = new FormControl<
    string | LisSelectOption<OptionKey> | null
  >(null);
  public availableOptions?: LisSelectOption<OptionKey>[];
  public selectedOption$ =
    new BehaviorSubject<LisSelectOption<OptionKey> | null>(null);

  private subscriptions = new Subscription();

  private refreshOptions$ = new BehaviorSubject<string>('');

  private readonly totalSuggestions = 10;

  private dcupl?: Dcupl;

  constructor(
    private cdRef: ChangeDetectorRef,
    private dcuplService: DcuplService
  ) {}

  ngOnInit(): void {
    this.dcupl = this.dcuplService.getRoleBasedDcupl();
    this.listenForSelectedOptionChanges();
    this.listenForInputChanges();
    this.listenForRefreshOptions();

    this.init();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getInputString(): string {
    const inputValue = this.inputFormControl.value;

    return typeof inputValue === 'string'
      ? inputValue
      : (inputValue?.label.replace('~~~', ' - ') ?? '');
  }

  public onInputFocus(): void {
    this.refreshOptions$.next(this.getInputString());
  }

  private listenForRefreshOptions(): void {
    this.subscriptions.add(
      this.refreshOptions$.subscribe((searchTerm) => {
        this.availableOptions = this.getSuggests(searchTerm).map((suggest) => ({
          key: suggest.value as OptionKey,
          label: suggest.value,
        }));
        this.cdRef.detectChanges();
      })
    );
  }

  private listenForSelectedOptionChanges(): void {
    this.subscriptions.add(
      this.selectedOption$.subscribe((selectedOption) => {
        if (!this.dcuplAttribute || !this.dcuplList) {
          return;
        }

        this.inputFormControl.setValue(selectedOption);

        if (selectedOption) {
          this.dcuplList.catalog.query.apply(
            {
              attribute: this.dcuplAttribute,
              operator: 'eq',
              value: selectedOption.key,
            },
            { mode: 'set' }
          );
        } else {
          this.dcuplList.catalog.query.remove({
            groupKey: this.dcuplAttribute,
          });
        }
      })
    );
  }

  private listenForInputChanges(): void {
    this.subscriptions.add(
      this.inputFormControl.valueChanges.subscribe(() => {
        this.refreshOptions$.next(this.getInputString());
        this.selected.emit(this.selectedOption$.getValue()?.key ?? null);
      })
    );
  }

  private async init(): Promise<void> {
    this.initSearchInputValue();
  }

  private async initSearchInputValue(): Promise<void> {
    if (!this.dcuplList) {
      return;
    }

    const metadata = this.dcuplList.catalog.fn.metadata();

    const createdByQuery = metadata.appliedQuery.queries.find(
      (query) => query.groupKey === this.dcuplAttribute
    ) as DcuplQueryGroup | undefined;

    let value: string | undefined;

    if (createdByQuery && createdByQuery.queries.length === 1) {
      value = createdByQuery.queries[0].value;
    }

    if (!value) {
      return;
    }

    // this.inputFormControl.setValue(value);
  }

  public trackByOption(
    index: number,
    item: LisSelectOption<OptionKey>
  ): OptionKey {
    return item.key;
  }

  public displayFn(option: LisSelectOption<OptionKey> | undefined): string {
    if (!option) {
      return '';
    }
    if (option.label === '~~~') {
      return '';
    }
    return option.label.replace('~~~', ' - ') ?? '';
  }

  public onToggleClick(): void {
    const isPanelOpen = this.inputAutoComplete?.panelOpen ?? false;
    setTimeout(() => {
      if (!this.inputElement) {
        return;
      }

      if (isPanelOpen) {
        this.inputElement.nativeElement.blur();
      } else {
        this.inputElement.nativeElement.focus();
      }
    }, 0);
  }

  public onAutocompleteClose(): void {
    this.inputFormControl.setValue(this.selectedOption$.getValue());
  }

  public onInputBlur(): void {
    const isPanelOpen = this.inputAutoComplete?.panelOpen ?? false;

    if (!isPanelOpen) {
      this.onAutocompleteClose();
    }
  }

  public onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    const selectedOption = event.option.value as LisSelectOption<OptionKey>;
    this.selectedOption$.next(selectedOption);
  }

  public onResetClick(): void {
    this.selectedOption$.next(null);
  }

  public getArrowButtonClasses(): string[] {
    const classes: string[] = [];

    if (this.inputAutoComplete?.panelOpen) {
      classes.push('bg-blue-515', 'text-actions');
    } else {
      classes.push('text-icon-actions');
    }

    return classes;
  }

  private getSuggests(
    searchTerm: string,
    exactMatch: boolean = false
  ): Suggestion[] {
    if (!this.dcuplAttribute || !this.dcuplList) {
      return [];
    }

    if (exactMatch) {
      searchTerm = `^${searchTerm}$`;
    }

    const suggestsStartsWith = this.getSuggestsByQuery(
      searchTerm,
      this.dcuplAttribute,
      true
    );
    const suggestsIncludes = this.getSuggestsByQuery(
      searchTerm,
      this.dcuplAttribute
    );

    const uniqueValues = uniqBy(
      [...suggestsStartsWith, ...suggestsIncludes],
      'value'
    );

    return uniqueValues.slice(0, this.totalSuggestions);
  }

  private getSuggestsByQuery(
    searchTerm: string,
    groupKey: string,
    shouldStartWith?: boolean
  ): Suggestion[] {
    if (!this.dcupl) {
      return [];
    }

    return sortBy(
      this.dcuplList.catalog.fn.suggest({
        attribute: this.dcuplAttribute,
        value: shouldStartWith ? `/^${searchTerm}/` : `/${searchTerm}/`,
        max: this.totalSuggestions,
        transform: ['lowercase'],
        relevantData: 'excludeQuery',
        excludeQuery: {
          groupKey,
        },
      }),
      'value'
    );
  }

  public getTextContent(
    option: LisSelectOption<OptionKey>
  ): LisTextContent | null {
    if (!this.config?.textContent) {
      return null;
    }

    return this.config.textContent(option.label);
  }
}
