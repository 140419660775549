<div class="relative">
  <input class="form-input relative form-focus-visible pr-[3.25rem]" type="search" [placeholder]="placeholder" [attr.aria-label]="placeholder" autocomplete="none" [matAutocomplete]="auto" #inputAutoComplete #inputElement [formControl]="inputFormControl" (blur)="onInputBlur()" (focus)="onInputFocus()" />
  <div class="absolute inset-y-0 right-0 flex items-center mr-2 space-x-1">
    <button type="button" aria-label="button-reset-filter" class="flex items-center text-icon-actions" *ngIf="selectedOption$ | async" (click)="onResetClick()">
      <lis-icon name="action_close-20"></lis-icon>
    </button>
    <button type="button" aria-label="autocomplete-dropdown-button" class="flex items-center rounded-full" [ngClass]="getArrowButtonClasses()" (click)="onToggleClick()">
      <lis-icon name="action_angle-down-chevron-20"></lis-icon>
    </button>
  </div>
</div>

<mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)" [displayWith]="displayFn" (closed)="onAutocompleteClose()">
  <mat-option class="group m-1 rounded" *ngFor="let option of availableOptions; trackBy: trackByOption" [value]="option">
    <div class="flex items-center justify-between py-1 px-2.5">
      <div class="min-h-7" *ngIf="getTextContent(option) as textContent; else normalContent">
        <lis-text-content [content]="textContent"></lis-text-content>
      </div>
      <ng-template #normalContent>
        <div class="h-7">
          <span class="grow text-input-text typo-body truncate">{{ option.label }}</span>
        </div>
      </ng-template>
      <!-- <span *ngIf="option.key === (selectedOption$ | async)?.key">
        <lis-icon class="text-actions" name="info_check-24"></lis-icon>
      </span> -->
    </div>
  </mat-option>
</mat-autocomplete>
