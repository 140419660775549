import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  LisFormFieldExtComponent,
  LisFormValue,
  LisSelectOption,
} from '@lis-form';
import { getUniqueId } from '@lis-helpers';

@Component({
  selector: 'lis-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioGroupComponent<OK extends LisFormValue>
  extends LisFormFieldExtComponent
  implements OnInit, OnDestroy
{
  @Input({ required: true }) options!: LisSelectOption<OK>[];

  public groupId = getUniqueId();

  constructor() {
    super();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  public trackByOption(index: number, option: LisSelectOption<OK>): string {
    return option.key.toString();
  }
}
