import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  LisFormFieldExtComponent,
  LisFormValue,
  LisSelectOption,
  LisSelectOptionBase,
  LisSelectOptionGroup,
} from '@lis-form';
import { compact } from 'lodash-es';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lis-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent<OptionKey extends LisFormValue>
  extends LisFormFieldExtComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input({ required: true }) options!: LisSelectOption<OptionKey>[];
  @Input() placeholder?: string;
  @Input({ required: true })
  override control!: FormControl<OptionKey | null>;
  @Input() isDark = false;

  public selectedOption?: LisSelectOptionBase<OptionKey>;
  public value?: OptionKey;

  public config?:
    | { mode: 'options'; options: LisSelectOptionBase<OptionKey>[] }
    | { mode: 'optionGroups'; optionGroups: LisSelectOptionGroup<OptionKey>[] };

  private subscriptions = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.control.valueChanges.subscribe((value) => {
        this.setSelectedOption(value);
      })
    );

    this.setSelectedOption(this.control.value);

    this.subscriptions.add(
      this.control.statusChanges.subscribe(() => {
        this.cdRef.detectChanges();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options']) {
      if (this.options.some((option) => option.groupLabel)) {
        // same groupLabel should be in the same group
        this.config = {
          mode: 'optionGroups',
          optionGroups: this.options
            .reduce((acc, option) => {
              const groupLabel: string = option.groupLabel ?? '';

              const group = acc.find((group) => group.label === groupLabel);

              if (group) {
                group.options.push(option);
              } else {
                acc.push({
                  label: groupLabel,
                  options: [option],
                });
              }

              return acc;
            }, [] as LisSelectOptionGroup<OptionKey>[])
            .sort((a, b) => a.label.localeCompare(b.label)),
        };
      } else {
        this.config = {
          mode: 'options',
          options: compact([
            this.placeholder
              ? {
                  key: '' as OptionKey,
                  label: this.placeholder,
                }
              : undefined,
            ...this.options,
          ]),
        };
      }
      this.setSelectedOption(this.control.value);
      this.cdRef.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setSelectedOption(key: OptionKey | null): void {
    this.selectedOption = this.options.find((item) => item.key === key);
    this.value = this.selectedOption?.key;
  }

  public onChange(option: string): void {
    const selectedOption = this.options.find(
      (o) => o.key.toString() === option.toString()
    );

    this.control.patchValue(selectedOption?.key ?? null);
    this.control.markAsTouched();
    this.control.markAsDirty();
  }

  public trackById(index: number, item: LisSelectOption<OptionKey>): OptionKey {
    return item.key;
  }

  public getClasses(): string[] {
    const classes = [];

    if (this.isDark) {
      classes.push('bg-framework-metanavi-background-mobile', 'text-text-gray');
    }
    if (this.shouldShowErrors()) {
      classes.push('form-error');
    }

    return classes;
  }

  public onBlur(): void {
    this.control.markAsTouched();
  }

  public trackByOptionGroup(
    index: number,
    item: LisSelectOptionGroup<OptionKey>
  ): number {
    return index;
  }
}
