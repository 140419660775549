<div class="relative">
  <input readonly class="form-input relative form-focus-visible pr-[3.25rem]" type="select" [attr.aria-label]="'multi-select'" [formControl]="inputFormControl" (click)="onOverlayToggle()" cdkOverlayOrigin #trigger="cdkOverlayOrigin" />
  <div class="absolute inset-y-0 left-0 ml-1.5 mt-1.5 pointer-events-none" *ngIf="currentFilters.length > 1">
    <div class="text-white dark:text-gray-900 bg-input-label rounded-full py-0.5 px-2 whitespace-nowrap typo-s2 flex max-w-fit gap-x-0.5 items-center">
      <span>{{ currentFilters.length }} {{ 'multi-select.options' | translate }}</span>
      <button type="button" aria-label="status-remove-filter" class="pointer-events-auto" (click)="onResetFilter()">
        <lis-icon [name]="'action_close-18'"></lis-icon>
      </button>
    </div>
  </div>
  <div class="absolute inset-y-0 right-0 flex items-center mr-2 space-x-1">
    <button *ngIf="currentFilters?.length === 1" aria-label="remove-selection" (click)="onResetFilter()">
      <lis-icon [name]="'action_close-18'"></lis-icon>
    </button>
    <button type="button" class="flex items-center rounded-full" [ngClass]="isOverlayOpen ? 'bg-blue-515 text-actions' : 'text-icon-actions'" (click)="onOverlayToggle()" aria-label="multi-select-arrow">
      <lis-icon name="action_angle-down-chevron-20"></lis-icon>
    </button>
  </div>
</div>

<ng-template *ngIf="availableSelectGroups?.length" cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOverlayOpen">
  <div (lisClickOutside)="onOutsideClick()" class="bg-background-white rounded p-2 shadow-floating flex flex-col divide-y divide-divider min-w-60 overflow-y-auto">
    <div class="pb-2 self-end">
      <button type="button" class="link-primary" (click)="onResetFilter()">{{ 'multi-select.reset-filter' | translate }}</button>
    </div>
    <div class="pt-2 space-y-2">
      <div *ngFor="let group of availableSelectGroups; trackBy: trackByGroup">
        <p *ngIf="group.groupKey" class="uppercase text-text-gray typo-body-xs mb-2">{{ group.groupKey }}</p>
        <div>
          <button *ngIf="group.options.length > 1 && group.groupKey" type="button" class="link-primary mb-2" (click)="onCheckAllStatesByGroup(group.groupKey)">{{ (isWholeGroupChecked(group) ? 'multi-select.deselect-all-pre' : 'multi-select.select-all-pre') | translate }} {{ group.groupKey.toLowerCase() }} {{ (isWholeGroupChecked(group) ? 'multi-select.deselect-all-post' : 'multi-select.select-all-post') | translate }}</button>
          <div class="space-y-1">
            <div *ngFor="let option of group.options; trackBy: trackByOption" class="flex items-center px-2 h-8 cursor-pointer hover:bg-interactive-hover focus:bg-interactive-focus rounded" (click)="onCheckBoxClick(getCheckboxControlByKey(option.key))">
              <div class="pointer-events-none">
                <lis-checkbox [innerLabel]="option.innerLabel" [control]="getCheckboxControlByKey(option.key)"></lis-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
