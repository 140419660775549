import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import {
  LisFormFieldCheckbox,
  LisFormFieldExtComponent,
  LisFormValue,
} from '@lis-form';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lis-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxGroupComponent<OptionKey extends LisFormValue>
  extends LisFormFieldExtComponent
  implements OnInit, OnDestroy
{
  @Input({ required: true }) options!: LisFormFieldCheckbox<OptionKey>[];

  private checkboxFormGroup = new FormGroup({});
  private subscriptions = new Subscription();

  ngOnInit(): void {
    this.initCheckboxFormGroup();
    this.listenForFormFieldChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private listenForFormFieldChanges(): void {
    this.subscriptions.add(
      this.checkboxFormGroup.valueChanges.subscribe(
        (value: { [key: string]: string }) => {
          this.control?.setValue(
            Object.keys(value).filter((key) => value[key] === 'checked')
          );
        }
      )
    );
  }

  public trackByOption(
    index: number,
    option: LisFormFieldCheckbox<OptionKey>
  ): string {
    return option.key.toString();
  }

  private initCheckboxFormGroup(): void {
    this.options.forEach((option) => {
      this.checkboxFormGroup.addControl(
        option.key.toString(),
        new FormControl(
          this.control?.value?.includes(option.key) ? 'checked' : 'unchecked'
        )
      );
    });
  }

  public getCheckboxControlByKey(
    key: OptionKey
  ): AbstractControl<OptionKey> | undefined {
    return this.checkboxFormGroup.get(key.toString()) ?? undefined;
  }
}
