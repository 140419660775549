import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { LisFormFieldExtComponent } from '@lis-form';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lis-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextareaComponent
  extends LisFormFieldExtComponent
  implements OnInit, OnDestroy
{
  @Input() rows = 3;
  @Input() placeholder = '';
  @Input() isReadonly = false;
  @Input({ required: true }) override control!: FormControl<string | null>;

  @ViewChild('textarea') textareaRef?: ElementRef<HTMLInputElement>;

  private subscriptions: Subscription = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.control.statusChanges.subscribe(() => {
        this.cdRef.detectChanges();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public getClasses(): string[] {
    const classes = [];
    if (this.shouldShowErrors()) {
      classes.push('form-error');
    }
    return classes;
  }

  public onBlur(): void {
    this.control.markAsTouched();
  }
}
