import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  LisFormFieldExtComponent,
  LisFormFieldTextType,
  LisInputValue,
} from '@lis-form';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lis-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent
  extends LisFormFieldExtComponent
  implements OnInit, OnDestroy
{
  @Input() placeholder = '';
  @Input() autocomplete = '';
  @Input() type: LisFormFieldTextType = 'text';
  @Input() isReadonly = false;
  @Input({ required: true })
  override control!: FormControl<LisInputValue | null>;

  private subscriptions = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.listenForStatusChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private listenForStatusChanges(): void {
    this.subscriptions.add(
      this.control.statusChanges.subscribe(() => {
        this.cdRef.detectChanges();
      })
    );
  }

  public onBlur(): void {
    this.control.markAsTouched();
  }

  public getClasses(): string[] {
    const classes = [];
    if (this.shouldShowErrors()) {
      classes.push('form-error');
    }

    if (this.isReadonly) {
      classes.push('form-readonly');
    }

    if (this.type === 'search') {
      classes.push('form-input-search');
    }

    return classes;
  }

  public isResetVisible(): boolean {
    return this.type === 'search' && this.control.value !== '';
  }

  public onResetClick(): void {
    this.control.setValue('');
  }
}
