<div class="flex items-start">
  <div class="flex items-start transition-colors duration-150 cursor-default">
    <button class="size-5 mt-px rounded border-2 relative flex items-center justify-center form-focus-visible" [ngClass]="getCheckboxClasses()" (click)="onClick()" aria-label="checkbox-btn" type="button">
      <div [ngClass]="state === 'checked' ? 'block' : 'hidden'">
        <lis-icon [name]="'info_check-24'"></lis-icon>
      </div>
      <div [ngClass]="state === 'intermediate' ? 'block' : 'hidden'">
        <lis-icon [name]="'action_minus-20'"></lis-icon>
      </div>
    </button>

    <button class="pl-2 pr-1 typo-body" [class]="getCheckboxLabelClasses()" (click)="onClick()" type="button">
      <span> {{ innerLabel | translate }}</span>
    </button>
  </div>
</div>
