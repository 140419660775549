import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { LisFormFieldExtComponent } from '@lis-form';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lis-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleComponent
  extends LisFormFieldExtComponent
  implements OnInit, OnDestroy
{
  @Input() innerLabel?: string;

  @Input({ required: true })
  override control!: FormControl<boolean | null>;

  public isChecked = false;

  private subscriptions = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.listenForFormControlChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private listenForFormControlChanges(): void {
    this.isChecked = this.control.value ?? false;
    this.subscriptions.add(
      this.control.valueChanges.subscribe((value) => {
        this.isChecked = value ?? false;
        this.cdRef.markForCheck();
      })
    );
  }

  public onChange(): void {
    this.control.patchValue(!this.isChecked);
    this.control.markAsDirty();
    this.control.markAsTouched();
  }

  public getLabelClasses(): string[] {
    const classes = [];

    if (this.control.disabled) {
      classes.push('text-gray-600');
    }

    return classes;
  }
}
