import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FileUploaderModule } from '@lis-ui/file-uploader';
import { NotificationModule } from '@lis-ui/notification';
import { TranslateModule } from '@ngx-translate/core';
import { AutocompleteModule } from '../autocomplete';
import { CheckboxModule } from '../checkbox';
import { CheckboxGroupModule } from '../checkbox-group';
import { ErrorModule } from '../error';
import { InputModule } from '../input';
import { RadioGroupModule } from '../radio-group';
import { SelectModule } from '../select';
import { TextareaModule } from '../textarea';
import { ToggleModule } from '../toggle';
import { FormFieldComponent } from './form-field.component';

@NgModule({
  declarations: [FormFieldComponent],
  imports: [
    CommonModule,
    InputModule,
    ErrorModule,
    SelectModule,
    CheckboxModule,
    RadioGroupModule,
    ToggleModule,
    TextareaModule,
    NotificationModule,
    TranslateModule,
    AutocompleteModule,
    CheckboxGroupModule,
    FileUploaderModule,
  ],
  exports: [FormFieldComponent],
})
export class FormFieldModule {}
