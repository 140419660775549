<div class="relative" *ngIf="config">
  <select [id]="htmlId" (change)="onChange(select.value)" [value]="value" class="form-select form-focus-visible" [ngClass]="getClasses()" #select [disabled]="control.disabled" (blur)="onBlur()">
    <ng-container *ngIf="config.mode === 'options'">
      <option [value]="option.key" *ngFor="let option of config.options; trackBy: trackById" [selected]="option === selectedOption">{{ option.label | translate }}</option>
    </ng-container>
    <ng-container *ngIf="config.mode === 'optionGroups'">
      <optgroup *ngFor="let group of config.optionGroups; trackBy: trackByOptionGroup" [label]="group.label | translate">
        <option [value]="option.key" *ngFor="let option of group.options; trackBy: trackById" [selected]="option === selectedOption">{{ option.label | translate }}</option>
      </optgroup>
    </ng-container>
  </select>
  <lis-icon [name]="'action_angle-down-chevron-20'" class="absolute right-0 inset-y-0 my-auto px-2 pointer-events-none text-icon-actions"></lis-icon>
</div>
