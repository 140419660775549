import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { LisFormFieldExtComponent, LisFormValue } from '@lis-form';
import { getUniqueId } from '@lis-helpers';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lis-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioComponent<OK extends LisFormValue>
  extends LisFormFieldExtComponent
  implements OnInit, OnDestroy
{
  @Input({ required: true }) key!: OK;
  @Input({ required: true }) label!: string;
  @Input({ required: true }) groupId!: string;

  private subscriptions = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {
    super();
    this.htmlId = getUniqueId();
  }

  ngOnInit(): void {
    this.listenForFormChange();
  }

  ngOnDestroy(): void {}

  private listenForFormChange(): void {
    this.subscriptions.add(
      this.control?.valueChanges.subscribe(() => {
        this.cdRef.detectChanges();
      })
    );
  }

  public isChecked(): boolean {
    return this.control?.value === this.key;
  }

  public getRadioClasses(): string[] {
    const classes = [];

    if (this.isChecked()) {
      classes.push('is-active');
    }
    return classes;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onChange(event: any): void {
    const value = event.target.value;

    this.control?.patchValue(value);
    this.control?.markAsTouched();
    this.control?.markAsDirty();
  }
}
