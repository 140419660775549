import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { LisCheckboxState, LisFormFieldExtComponent } from '@lis-form';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lis-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent
  extends LisFormFieldExtComponent
  implements OnInit, OnDestroy
{
  @Input({ required: true }) innerLabel = '';

  public state: LisCheckboxState = 'unchecked';

  private subscriptions = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.listenForFormControlChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private listenForFormControlChanges(): void {
    this.state = this.convertValueToState(this.control?.value);
    this.subscriptions.add(
      this.control?.valueChanges.subscribe((value) => {
        this.state = this.convertValueToState(value);
        this.cdRef.markForCheck();
      })
    );
  }

  private convertValueToState(value: unknown): LisCheckboxState {
    switch (value) {
      case 'checked':
        return 'checked';
      case 'unchecked':
        return 'unchecked';
      case 'intermediate':
        return 'intermediate';
    }

    return 'unchecked';
  }

  public onClick(): void {
    if (this.control?.disabled) {
      return;
    }

    const newState = this.state === 'unchecked' ? 'checked' : 'unchecked';

    this.control?.patchValue(newState);
    this.control?.markAsDirty();
    this.control?.markAsTouched();
  }

  public getCheckboxClasses(): string[] {
    const classes: string[] = [];

    if (this.state === 'checked' || this.state === 'intermediate') {
      classes.push('border-transparent', 'text-input-background');

      if (!this.control?.disabled) {
        classes.push('bg-input-outline-focused');
      } else {
        classes.push('bg-gray-400');
      }
    }

    if (this.state === 'unchecked') {
      classes.push('border-input-outline');
    }

    return classes;
  }

  public getCheckboxLabelClasses(): string[] {
    const classes: string[] = [];

    if (this.control?.disabled) {
      classes.push('text-gray-550');
    } else {
      classes.push('text-input-text');
    }

    return classes;
  }
}
