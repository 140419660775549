import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { IconModule } from '@lis-ui/icon';
import { TextContentModule } from '@lis-ui/text-content';
import { TranslateModule } from '@ngx-translate/core';
import { InputModule } from '../input';
import { AutocompleteComponent } from './autocomplete.component';

@NgModule({
  declarations: [AutocompleteComponent],
  imports: [
    CommonModule,
    IconModule,
    InputModule,
    TranslateModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    TextContentModule,
  ],
  exports: [AutocompleteComponent],
})
export class AutocompleteModule {}
