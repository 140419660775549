import { Component, Input } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';

@Component({
  template: '',
})
export abstract class LisFormFieldExtComponent {
  @Input() control?: AbstractControl;
  @Input() htmlId?: string;

  constructor() {}

  protected isTouched(): boolean {
    return !!this.control?.touched;
  }

  protected getErrors(): ValidationErrors | undefined {
    if (this.control?.errors) {
      return this.control.errors;
    }

    return undefined;
  }

  protected shouldShowErrors(): boolean {
    return this.isTouched() && !!this.getErrors() && !this.control?.disabled;
  }
}
