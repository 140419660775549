import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { DcuplQueryBuilder } from '@dcupl/common';
import { Dcupl, DcuplList } from '@dcupl/core';
import { LisFormFieldCheckbox } from '@lis-form';
import { DcuplService } from '@lis-services';
import {
  LisTableModel,
  LisTableMultiSelectGroup,
  LisTableSelectOptions,
} from '@lis-types';
import { first, orderBy, sortBy, uniqBy } from 'lodash-es';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'lis-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectComponent<
    Model extends LisTableModel,
    OptionKey extends string,
  >
  implements OnInit, OnDestroy
{
  @Input({ required: true }) dcuplList!: DcuplList;
  @Input({ required: true }) selectOptions!: LisTableSelectOptions<Model>;

  @Output() selected = new EventEmitter<string[] | null>();

  public isOverlayOpen = false;

  public availableSelectGroups?: LisTableMultiSelectGroup<OptionKey>[] | null;
  public currentFilters: string[] = [];

  private formGroup = new FormGroup({});
  public inputFormControl = new FormControl<string | null>(null);

  private subscriptions = new Subscription();

  private dcupl?: Dcupl;

  private refreshOptions$ = new Subject<void>();

  constructor(
    private cdRef: ChangeDetectorRef,
    private dcuplService: DcuplService
  ) {}

  ngOnInit(): void {
    this.dcupl = this.dcuplService.getRoleBasedDcupl();
    this.listenForRefreshOptions();
    this.initCheckboxFormGroup();
    this.listenForFormValueChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private listenForRefreshOptions(): void {
    this.subscriptions.add(
      this.refreshOptions$.subscribe(() => {
        this.availableSelectGroups = this.getGroupedSelectOptions();
        this.cdRef.markForCheck();
      })
    );
  }

  private initCheckboxFormGroup(): void {
    const allSelectGroups = this.getGroupedSelectOptions();

    allSelectGroups.forEach((group) => {
      group.options.forEach((option) => {
        this.formGroup.addControl(
          option.key.toString(),
          new FormControl('unchecked')
        );
      });
    });
  }

  private listenForFormValueChanges(): void {
    this.subscriptions.add(
      this.formGroup.valueChanges.subscribe((value) => {
        const entries = Object.entries<string>(value);

        // check whether there is more than one checked value
        this.currentFilters = Object.keys(value).filter(
          (key: string) =>
            (value as { [key: string]: string })[key] === 'checked'
        );

        if (this.currentFilters.length > 1) {
          this.inputFormControl.setValue(null);
        } else {
          const inputValue: string[] | null =
            entries.find((entry) => {
              return entry[1] === 'checked';
            }) ?? null;

          this.inputFormControl.setValue(first(inputValue) ?? '');
        }

        if (!this.selectOptions || !this.dcuplList) {
          return;
        }

        const queries = this.currentFilters.map((key) => {
          return {
            attribute: this.selectOptions.optionKey.toString(),
            operator: 'eq',
            value: key,
          };
        });

        if (this.currentFilters.length) {
          this.dcuplList.catalog.query.apply(
            {
              groupKey: this.selectOptions.optionKey.toString(),
              groupType: 'or',
              queries,
            },
            { mode: 'set' }
          );
        } else {
          this.dcuplList.catalog.query.remove({
            groupKey: this.selectOptions.optionKey.toString(),
          });
        }

        this.selected.emit(
          this.currentFilters.length ? this.currentFilters : null
        );

        let shouldResetAll = true;

        this.availableSelectGroups?.forEach((group) => {
          group.options.forEach((option) => {
            if (
              this.formGroup.get(option.key.toString())?.value === 'checked'
            ) {
              shouldResetAll = false;
            }
          });
        });

        if (shouldResetAll && this.formGroup.touched) {
          this.formGroup.reset();
          return;
        }

        this.formGroup.markAsTouched();
      })
    );
  }

  public onCheckAllStatesByGroup(groupKey: string): void {
    const group = this.availableSelectGroups?.find(
      (group) => group.groupKey === groupKey
    );
    if (!group) {
      return;
    }

    if (this.isWholeGroupChecked(group)) {
      return group.options.forEach((option) => {
        this.formGroup.get(option.key.toString())?.setValue('unchecked');
      });
    }

    return group.options.forEach((option) => {
      return this.formGroup.get(option.key.toString())?.setValue('checked');
    });
  }

  public isWholeGroupChecked(
    group: LisTableMultiSelectGroup<OptionKey>
  ): boolean {
    return group.options.every(
      (option) => this.formGroup.get(option.key)?.value === 'checked'
    );
  }

  public onResetFilter(): void {
    this.formGroup.reset();
  }

  public onCheckBoxClick(control?: AbstractControl): void {
    if (!control) {
      return;
    }
    control.setValue(control.value === 'checked' ? 'unchecked' : 'checked', {
      emitModelToViewChange: true,
    });
  }

  private getGroupedSelectOptions(): LisTableMultiSelectGroup<OptionKey>[] {
    const modelKey = this.dcuplList.modelKey;

    const dcuplList = this.dcupl?.lists.create({
      modelKey,
    });
    if (!dcuplList) {
      return [];
    }

    const currentQuery = this.dcuplList.catalog.query.get();

    if (currentQuery) {
      dcuplList.catalog.query.apply(currentQuery);
    }
    dcuplList.catalog.query.remove({
      groupKey: this.selectOptions.optionKey.toString(),
    });

    if (!this.selectOptions.groupKey) {
      return [
        {
          groupKey: null,
          options: uniqBy(
            sortBy(
              dcuplList.catalog.fn.suggest({
                attribute: this.selectOptions.optionKey as string,
                value: `//`,
                transform: ['lowercase'],
                relevantData: 'excludeQuery',
                excludeQuery: {
                  groupKey: this.selectOptions.optionKey as string,
                },
              }),
              this.selectOptions.optionKey
            ).map((item) => {
              return {
                type: 'checkbox',
                key: item.value as OptionKey,
                innerLabel: item.value,
              };
            }),
            'key'
          ),
        },
      ];
    }

    const suggests = dcuplList.catalog.fn.suggest({
      attribute: this.selectOptions.groupKey as string,
      value: `//`,
      transform: ['lowercase'],
      relevantData: 'excludeQuery',
      excludeQuery: {
        groupKey: this.selectOptions.groupKey as string,
      },
    });

    const selectGroups: LisTableMultiSelectGroup<OptionKey>[] = [];

    suggests.forEach((suggest) => {
      const queryBuilder = new DcuplQueryBuilder();
      queryBuilder.init({
        modelKey,
      });

      const currentQuery = dcuplList.catalog.query.get();
      if (currentQuery) {
        queryBuilder.applyQuery(currentQuery);
      }
      queryBuilder.applyQuery({
        attribute: this.selectOptions.groupKey as string,
        operator: 'eq',
        value: suggest.value,
      });
      const query = queryBuilder.getQuery();

      const optionSuggests =
        this.dcupl?.fn.suggest({
          modelKey,
          options: {
            attribute: this.selectOptions.optionKey as string,
            value: `//`,
          },
          query,
        }) ?? [];

      const options: LisFormFieldCheckbox<OptionKey>[] = optionSuggests.map(
        (s) => ({
          type: 'checkbox',
          key: s.value as OptionKey,
          innerLabel: s.value,
        })
      );

      selectGroups.push({
        groupKey: suggest.value,
        options,
      });
    });

    return orderBy(selectGroups, this.selectOptions.customGroupOrder);
  }

  public getCheckboxControlByKey(
    key: OptionKey
  ): AbstractControl<OptionKey> | undefined {
    return this.formGroup.get(key.toString()) ?? undefined;
  }

  public onOutsideClick(): void {
    this.isOverlayOpen = false;
  }

  public onOverlayToggle(): void {
    // necessary for outsideclick
    if (this.isOverlayOpen) {
      setTimeout(() => {
        this.isOverlayOpen = false;
        this.cdRef.markForCheck();
      });
    } else {
      this.refreshOptions$.next();
      setTimeout(() => {
        this.isOverlayOpen = true;
        this.cdRef.markForCheck();
      });
    }
  }

  public trackByGroup(index: number): number {
    return index;
  }
  public trackByOption(index: number, option: LisFormFieldCheckbox): string {
    return option.key.toString();
  }
}
