import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IconModule } from '@lis-ui/icon';
import { ErrorModule } from 'src/app/shared/form/ui/error';

import { InputComponent } from './input.component';

@NgModule({
  declarations: [InputComponent],
  imports: [CommonModule, ErrorModule, ReactiveFormsModule, IconModule],
  exports: [InputComponent],
})
export class InputModule {}
