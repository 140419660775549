import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@lis-ui/icon';
import { TranslateModule } from '@ngx-translate/core';
import { SelectComponent } from './select.component';

@NgModule({
  declarations: [SelectComponent],
  imports: [CommonModule, IconModule, TranslateModule],
  exports: [SelectComponent],
})
export class SelectModule {}
