import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { CheckboxGroupComponent } from './checkbox-group.component';

@NgModule({
  imports: [CommonModule, CheckboxModule],
  declarations: [CheckboxGroupComponent],
  exports: [CheckboxGroupComponent],
})
export class CheckboxGroupModule {}
