import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { LisIcon, LisToastType } from '@lis-types';

@Component({
  selector: 'lis-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent implements OnInit {
  @Input({ required: true }) type!: LisToastType;
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() fullWidth = false;
  @Input() hasSystemMessageStyle = false;
  @Input() hasCloseButton = false;
  @Input() isGlobal = false;

  @Output() closed = new EventEmitter<void>();

  private readonly toastConfig: {
    [key in LisToastType]: {
      icon: LisIcon;
      iconContainerClasses: string[];
      textContainerClasses: string[];
    };
  } = {
    error: {
      icon: 'toast_error-40',
      iconContainerClasses: ['bg-red-500'],
      textContainerClasses: ['bg-red-510'],
    },
    info: {
      icon: 'toast_info-40',
      iconContainerClasses: ['bg-blue-500'],
      textContainerClasses: ['bg-blue-515'],
    },
    success: {
      icon: 'toast_success-40',
      iconContainerClasses: ['bg-green-500'],
      textContainerClasses: ['bg-green-510'],
    },
    warning: {
      icon: 'toast_warning-40',
      iconContainerClasses: ['bg-orange-500'],
      textContainerClasses: ['bg-orange-510'],
    },
  };

  public iconContainerClasses?: string[];
  public textContainerClasses?: string[];
  public wrapperClasses?: string[];

  public icon?: LisIcon;

  constructor() {}

  ngOnInit(): void {
    this.init();
  }

  private init(): void {
    const config = this.toastConfig[this.type];
    this.icon = config.icon;
    this.iconContainerClasses = config.iconContainerClasses;

    this.textContainerClasses = this.hasSystemMessageStyle
      ? ['bg-banner-background']
      : config.textContainerClasses;

    const wrapperClasses: string[] = [];

    if (this.fullWidth) {
      wrapperClasses.push('w-full');
    }

    if (!this.hasSystemMessageStyle) {
      wrapperClasses.push('sm:max-w-xl', 'rounded-lg');
    }

    if (this.isGlobal) {
      wrapperClasses.push('shadow-lg');
    }

    this.wrapperClasses = wrapperClasses;
  }

  public onCloseClick(): void {
    this.closed.emit();
  }
}
