import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@lis-ui/button';
import { AutocompleteModule } from './autocomplete';
import { CheckboxModule } from './checkbox';
import { FormFieldModule } from './form-field';
import { InputModule } from './input';
import { MultiSelectModule } from './multi-select';
import { RadioGroupModule } from './radio-group';
import { SelectModule } from './select';
import { TextareaModule } from './textarea';
import { ToggleModule } from './toggle';

@NgModule({
  declarations: [],
  imports: [CommonModule, FormFieldModule, ButtonModule],
  exports: [
    FormFieldModule,
    SelectModule,
    CheckboxModule,
    InputModule,
    ToggleModule,
    RadioGroupModule,
    TextareaModule,
    AutocompleteModule,
    MultiSelectModule,
  ],
})
export class FormModule {}
