import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@lis-ui/icon';
import { ToastModule } from '@lis-ui/toast';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorModule } from '../../form/ui/error';
import { FileUploaderComponent } from './file-uploader.component';

@NgModule({
  declarations: [FileUploaderComponent],
  imports: [
    CommonModule,
    TranslateModule,
    IconModule,
    ErrorModule,
    ToastModule,
  ],
  exports: [FileUploaderComponent],
})
export class FileUploaderModule {}
